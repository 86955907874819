import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../../images/logoneww.png'
import { NavLink, Link } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { logout } from '../../actions/user_actions'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const NavBar = () => {

    const alert = useAlert();
    const dispatch = useDispatch();
    const [menuOpen, setMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const { user, isAuthenticated, loading } = useSelector(state => state.auth)

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };
    const logoutHandler = () => {
        localStorage.removeItem('userToken');
        dispatch(logout());
        alert.success("logOut")
    }
    useEffect(() => {
        const img = new Image();
        img.src = logo;

        const handleScroll = () => {
            const isScrolled = window.scrollY > 50;
            if (isScrolled !== scrolled) {
                setScrolled(isScrolled);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const navbarHeight = document.getElementById('navbar').offsetHeight;
            window.scrollTo({
                top: section.offsetTop,
                behavior: 'smooth'
            });
        }
    };
    return (
        <Fragment>
        <nav aria-label="Navbar International Driving School" className={scrolled ? 'scrolled' : ''} id="navbar">
            <Link to="/home" aria-label="Ir a inicio">
                <div className='navbar-brand logo'>
                    <LazyLoadImage src={logo} alt="International" />
                </div>
            </Link>
            <div className={`menuToggle${menuOpen ? ' open' : ''}`} onClick={toggleMenu}>
                <i className={menuOpen ? 'bi bi-x' : 'bi bi-list'}></i>
            </div>
            <ul className={`navigation${menuOpen ? ' open' : ''}`} onClick={closeMenu}>
                <li className="nav-item" aria-label="Ir a Inicio">
                    <NavLink to="/#banner" className="nav-link" onClick={() => scrollToSection('banner')}>INICIO</NavLink>
                </li>
                <li className="nav-item" aria-label="Ir a servicios">
                    <NavLink to="/#services" className="nav-link" onClick={() => scrollToSection('services')}>SERVICIOS</NavLink>
                </li>
                <li className="nav-item" aria-label="Ir acerca de nosotros">
                    <NavLink to="/#about" className="nav-link" onClick={() => scrollToSection('about')}>NOSOTROS</NavLink>
                </li>
                <li className="nav-item" aria-label="Ir a contactanos">
                    <NavLink to="/#reviews" className="nav-link" onClick={() => scrollToSection('reviews')}>CONTACTANOS</NavLink>
                </li>
                <li className="nav-item" aria-label="Ir a Reviews">
                    <NavLink to="/comentarios" className="nav-link" >REVIEWS</NavLink>
                </li>
                {isAuthenticated && (
                    <li className="nav-item dropdown">
                        <NavLink className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                            <figure className='avatar avatar-nav'>
                                <LazyLoadImage
                                    src={user.avatar && user.avatar.url}
                                    alt={user && user.nombre}
                                    className="rounded-circle"
                                />
                            </figure>
                        </NavLink>
                        <ul className="dropdown-menu" id='subMenuWrap'>
                            <div className='userInfo'>
                                <figure className='avatar avatar-nav'>
                                    <LazyLoadImage
                                        src={user.avatar && user.avatar.url}
                                        alt={user && user.nombre}
                                        className="rounded-circle"
                                    />
                                </figure>
                                <h3>{user.nombre}&nbsp;{user.apellido}</h3>
                            </div>
                            <hr />
                            {user && user.role === "admin" && (
                                <li aria-label="Ir a administracion">
                                    <Link className="dropdown-item" to="/admin/dashboard" id='subMenuLink'>
                                        <i className="bi bi-gear-fill"></i> Adm. Servicios
                                    </Link>
                                </li>
                            )}
                            <li aria-label="Ir a Perfil">
                                <Link className="dropdown-item" to="/MyProfile" id='subMenuLink'>
                                    <i className="bi bi-person-bounding-box"></i> Perfil
                                </Link>
                            </li>
                            <li aria-label="Cerrar Sesion">
                                <NavLink className="dropdown-item" to={"/"} onClick={logoutHandler} id='subMenuLink'>
                                    <i className="bi bi-box-arrow-right"></i>Log out
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                )}
                {!isAuthenticated && !loading && (
                    <li className="nav-item" aria-label="Ir a login">
                        <NavLink to={"/login"} className="nav-link text-white" id='login_btn'>LOGIN</NavLink>
                    </li>
                )}
            </ul>
            {menuOpen && (
                <ul className='subMenuWrapAll'>
                    <li><NavLink to="/#banner" className="dropdown-item" id='subMenuLink' onClick={() => { scrollToSection('banner'); closeMenu(); }}>INICIO</NavLink></li>
                    <li><NavLink to="/#services" className="dropdown-item" id='subMenuLink' onClick={() => { scrollToSection('services'); closeMenu(); }}>SERVICIOS</NavLink></li>
                    <li><NavLink to="/#about" className="dropdown-item" id='subMenuLink' onClick={() => { scrollToSection('about'); closeMenu(); }}>NOSOTROS</NavLink></li>
                    <li><NavLink to="/#reviews" className="dropdown-item" id='subMenuLink' onClick={() => { scrollToSection('reviews'); closeMenu(); }}>CONTACTANOS</NavLink></li>
                    <li><NavLink to="/comentarios" className="dropdown-item" id='subMenuLink' onClick={() => closeMenu()} >REVIEWS</NavLink></li>
                    {isAuthenticated ? (
                        <>
                            <div className='userInfo'>
                                <figure className='avatar avatar-nav'>
                                    <LazyLoadImage
                                        src={user.avatar && user.avatar.url}
                                        alt={user && user.nombre}
                                        className="rounded-circle"></LazyLoadImage>
                                </figure>
                                <h3>{user.nombre}&nbsp;{user.apellido}</h3>
                            </div>
                            <hr></hr>
                            {user && user.role === "admin" && (
                                <li><Link className="dropdown-item" to="/admin/dashboard" id='subMenuLink' onClick={() => closeMenu()}><i className="bi bi-gear-fill"></i> Adm. Servicios </Link></li>
                            )}
                            <li><Link className="dropdown-item" to="/MyProfile" id='subMenuLink' onClick={() => closeMenu()}><i className="bi bi-person-bounding-box" ></i> Perfil </Link></li>
                            <li><NavLink className="dropdown-item" to={"/"} onClick={() => { logoutHandler(); closeMenu(); }} id='subMenuLink' ><i className="bi bi-box-arrow-right"></i>Log out </NavLink></li>
                        </>
                    ) : !loading && (
                        <li><NavLink to={"/login"} className="nav-link text-white" id='login_button' onClick={() => closeMenu()}>LOGIN</NavLink></li>
                    )}
                </ul>
            )}
        </nav>
    </Fragment >
    )
}
export default NavBar;