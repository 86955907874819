export const ALL_SERVICES_REQUEST="ALL_SERVICES_REQUEST"
export const ALL_SERVICES_SUCCES="ALL_SERVICES_SUCCES"
export const ALL_SERVICES_FAIL="ALL_SERVICES_FAIL"

export const SERVICE_DETAILS_REQUEST="SERVICE_DETAILS_REQUEST"
export const SERVICE_DETAILS_SUCCES="SERVICE_DETAILS_SUCCES"
export const SERVICE_DETAILS_FAIL="SERVICE_DETAILS_FAIL"

export const PRODUCT_DETAILS_REQUEST="PRODUCT_DETAILS_REQUEST"
export const PRODUCT_DETAILS_SUCCESS="PRODUCT_DETAILS_SUCCESS"
export const PRODUCT_DETAILS_FAIL="PRODUCT_DETAILS_FAIL"

export const CREATE_SERVICE_REQUEST="CREATE_SERVICE_REQUEST"
export const CREATE_SERVICE_SUCCESS="CREATE_SERVICE_SUCCESS"
export const CREATE_SERVICE_RESET="CREATE_SERVICE_RESET"
export const CREATE_SERVICE_FAIL="CREATE_SERVICE_FAIL"

export const DELETE_SERVICE_REQUEST="DELETE_SERVICE_REQUEST"
export const DELETE_SERVICE_SUCCESS="DELETE_SERVICE_SUCCESS"
export const DELETE_SERVICE_FAIL="DELETE_SERVICE_FAIL"

export const UPDATE_SERVICE_REQUEST="UPDATE_SERVICE_REQUEST"
export const UPDATE_SERVICE_SUCCESS="UPDATE_SERVICE_SUCCESS"
export const UPDATE_SERVICE_FAIL="UPDATE_SERVICE_FAIL"
export const UPDATE_SERVICE_RESET="UPDATE_SERVICE_RESET"

export const NEW_PRODUCT_SUBCATEGORIE_REQUEST="NEW_PRODUCT_SUBCATEGORIE_REQUEST"
export const NEW_PRODUCT_SUBCATEGORIE_SUCCESS="NEW_PRODUCT_SUBCATEGORIE_SUCCESS"
export const NEW_PRODUCT_SUBCATEGORIE_RESET="NEW_PRODUCT_SUBCATEGORIE_RESET"
export const NEW_PRODUCT_SUBCATEGORIE_FAIL="NEW_PRODUCT_SUBCATEGORIE_FAIL"

export const DELETE_SUBCATEGORIE_REQUEST="DELETE_SUBCATEGORIE_REQUEST"
export const DELETE_SUBCATEGORIE_SUCCESS="DELETE_SUBCATEGORIE_SUCCESS"
export const DELETE_SUBCATEGORIE_FAIL="DELETE_SUBCATEGORIE_FAIL"
export const DELETE_SUBCATEGORIE_RESET="DELETE_SUBCATEGORIE_RESET"

export const UPDATE_SUBCATEGORIE_REQUEST="UPDATE_SUBCATEGORIE_REQUEST"
export const UPDATE_SUBCATEGORIE_SUCCESS="UPDATE_SUBCATEGORIE_SUCCESS"
export const UPDATE_SUBCATEGORIE_FAIL="UPDATE_SUBCATEGORIE_FAIL"
export const UPDATE_SUBCATEGORIE_RESET="UPDATE_SUBCATEGORIE_RESET"

export const GET_SUBCATEGORIE_REQUEST = 'GET_SUBCATEGORIE_REQUEST'
export const GET_SUBCATEGORIE_SUCCESS = 'GET_SUBCATEGORIE_SUCCESS'
export const GET_SUBCATEGORIE_FAIL = 'GET_SUBCATEGORIE_FAIL'

export const  CLEAR_ERRORS="CLEAR_ERRORS"