import React, { Fragment } from 'react'

export const Footer = () => {
    return (
        <Fragment>
            <footer>
                <div className='container containerFooter'>
                    <div className='footerContent'>
                        <h3 id='contactH3'>Contactanos</h3>
                        <p><a href='https://wa.me/13054912288' aria-label="Comunicarme al Whatsapp"><i className="bi bi-whatsapp"></i></a>(305) 491-2288</p>
                        <p aria-label="Correo electronico int.drivingacademy@gmail.com"><i className="bi bi-envelope-at" ></i> int.drivingacademy@gmail.com</p>
                    </div>
                    <div className='footerContent'>
                        <h3>Links</h3>
                        <ul className='list'>
                            <li><a href="/#banner" aria-label="Ir a Inicio">Home</a></li>
                            <li><a href="/#about" aria-label="Ir acerca de nosotros">About Us</a></li>
                            <li><a href="/#services" aria-label="Ir a Servicios">Services</a></li>
                            <li><a href="/#reviews" aria-label="Ir a contactanos">Contact Us</a></li>
                        </ul>
                    </div>
                    <div className='footerContent'>
                        <h3>Siguenos</h3>
                        <ul className='socialIcons'>
                            <li><a href="https://www.facebook.com/profile.php?id=61554980072645" target="_blank" rel="noopener noreferrer" aria-label="Comunicarme al Whatsapp"><i className="bi bi-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/int.drivingacademy//" target="_blank" rel="noopener noreferrer" aria-label="Visitar Instagram"><i className="bi bi-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className='footerBot'>
                    <span className="cafe"><i className="bi bi-c-circle"></i> Copyright 2023 • International Driving School • <a href='/privacy-policy'>Privacy</a> • <a href='/terminos'>Terminos</a> • Designed by <a href='https://github.com/Mishaker22'> Mishaker</a></span>
                </div>
            </footer>
        </Fragment>
    )
}
export default Footer;