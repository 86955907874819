//Loguear
export const LOGIN_REQUEST="LOGIN_REQUEST"
export const LOGIN_SUCCESS="LOGIN_SUCCESS"
export const LOGIN_FAIL="LOGIN_FAIL"

//Registrar un usuario
export const REGISTER_USER_REQUEST ="REGISTER_USER_REQUEST"
export const REGISTER_USER_SUCCESS ="REGISTER_USER_SUCCESS"
export const REGISTER_USER_FAIL ="REGISTER_USER_FAIL"

//Mostrar todos los usuarios
export const ALL_USERS_REQUEST="ALL_USERS_REQUEST"
export const ALL_USERS_SUCCES="ALL_USERS_SUCCES"
export const ALL_USERS_FAIL="ALL_USERS_FAIL"

//Mostrar un usuario
export const LOAD_USER_REQUEST ="LOAD_USER_REQUEST"
export const LOAD_USER_SUCCESS ="LOAD_USER_SUCCESS"
export const LOAD_USER_FAIL ="LOAD_USER_FAIL"

//Cerrar Sesion
export const LOGOUT_SUCCESS ="LOGOUT_SUCCESS"
export const LOGOUT_FAIL ="LOGOUT_FAIL"

//Editar Perfil
export const PROFILE_UPDATE_REQUEST="PROFILE_UPDATE_REQUEST"
export const PROFILE_UPDATE_SUCCESS="PROFILE_UPDATE_SUCCESS"
export const PROFILE_UPDATE_FAIL="PROFILE_UPDATE_FAIL"
export const PROFILE_UPDATE_RESET="PROFILE_UPDATE_RESET"

//Cambiar contraseña
export const PASSWORD_UPDATE_REQUEST="PASSWORD_UPDATE_REQUEST"
export const PASSWORD_UPDATE_SUCCESS="PASSWORD_UPDATE_SUCCESS"
export const PASSWORD_UPDATE_FAIL="PASSWORD_UPDATE_FAIL"
export const PASSWORD_UPDATE_RESET="PASSWORD_UPDATE_RESET"

//Olvide contraseña
export const FORGOT_PASSWORD_REQUEST="FORGOT_PASSWORD_REQUEST"
export const FORGOT_PASSWORD_SUCCESS="FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAIL="FORGOT_PASSWORD_FAIL" 

//Crear nueva contraseña
export const NEW_PASSWORD_REQUEST="NEW_PASSWORD_REQUEST"
export const NEW_PASSWORD_SUCCESS="NEW_PASSWORD_SUCCESS"
export const NEW_PASSWORD_FAIL="NEW_PASSWORD_FAIL" 
 
//Editar un usuario rol
export const USER_UPDATE_REQUEST="USER_UPDATE_REQUEST"
export const USER_UPDATE_SUCCESS="USER_UPDATE_SUCCESS"
export const USER_UPDATE_RESET="USER_UPDATE_RESET"
export const USER_UPDATE_FAIL="USER_UPDATE_FAIL"

//Eliminar un usuario
export const DELETE_USER_REQUEST="DELETE_USER_REQUEST"
export const DELETE_USER_SUCCESS="DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL="DELETE_USER_FAIL"

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'

export const CLEAR_ERRORS="CLEAR_ERRORS"