import './api.css';
import './App.css';
import React, { useEffect, Suspense } from 'react';

import { Inicio } from './components/Inicio'
import { NavBar } from './components/layout/NavBar';
import { Footer } from './components/layout/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import store from './store';
import { loadUser } from './actions/user_actions';
import ProtectedRoutes from './routes/Protected_routes';



// Componentes dinámicos
const Banner = React.lazy(() => import('./components/banner'));
const Nosotros = React.lazy(() => import('./components/nosotros'));
const Services = React.lazy(() => import('./components/services/services'));
const ServiceDetails = React.lazy(() => import('./components/services/service_details'));
const Login = React.lazy(() => import('./components/users/login'));
const Register = React.lazy(() => import('./components/users/register'));
const Profile = React.lazy(() => import('./components/users/profile'));
const UpdateProfile = React.lazy(() => import('./components/users/updateProfile'));
const UpdatePassword = React.lazy(() => import('./components/users/updatePassword'));
const ForgotPassword = React.lazy(() => import('./components/users/forgotPassword'));
const ResetPassword = React.lazy(() => import('./components/users/resetPassword'));
const Reviews = React.lazy(() => import('./components/reviews/reviews'));
const Comentarios = React.lazy(() => import('./components/reviews/comentarios'));
const Dashboard = React.lazy(() => import('./components/admin/dashboard'));
const ServiceList = React.lazy(() => import('./components/admin/serviceList'));
const NewService = React.lazy(() => import('./components/admin/service_new'));
const UpdateService = React.lazy(() => import('./components/admin/updateService'));
const SubcategorieNew = React.lazy(() => import('./components/admin/subcategorie_new'));
const UpdateProduct = React.lazy(() => import('./components/admin/updateProduct'));
const UserList = React.lazy(() => import('./components/admin/UserList'));
const UpdateUser = React.lazy(() => import('./components/admin/updateUser'));
const Employee = React.lazy(() => import('./components/admin/employee'));
const ListReviews = React.lazy(() => import('./components/admin/listReviews'))
const PrivacyPolicy = React.lazy(() => import('./components/PrivacyPolicy'));
const Terminos = React.lazy(() => import('./components/Terminos'));
function App() {

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    console.log('Valor de userToken:', userToken);
    if (userToken) {
      store.dispatch(loadUser());
    }
  }, [])
 
  return (
    <Router>
      <div className="App">
        <NavBar></NavBar>
        <Suspense fallback={<h2>loading</h2>}>
          <Routes>
            <Route path='/' element={<Inicio></Inicio>}></Route>
            <Route path='/home' element={<Inicio></Inicio>}></Route>
            <Route path='/banner' element={<Banner></Banner>}></Route>
            <Route path='/nosotros' element={<Nosotros></Nosotros>}></Route>
            <Route path='/services' element={<Services></Services>}></Route>
            <Route path='/service/:id' element={<ServiceDetails />}></Route>
            <Route path='/login' element={<Login></Login>}></Route>
            <Route path='/register' element={<Register></Register>}></Route>
            <Route path='/MyProfile' element={<Profile></Profile>}></Route>
            <Route path='/MyProfile/update' element={<UpdateProfile></UpdateProfile>}></Route>
            <Route path='/MyProfile/updatePassword' element={<UpdatePassword></UpdatePassword>}></Route>
            <Route path='/forgotPassword' element={<ForgotPassword></ForgotPassword>}></Route>
            <Route path='/resetPassword/:token' element={<ResetPassword></ResetPassword>}></Route>
            <Route path='/reviews' element={<Reviews></Reviews>}></Route>
            <Route path='/comentarios' element={<Comentarios></Comentarios>}></Route>
            <Route path='/privacy-policy' element={<PrivacyPolicy></PrivacyPolicy>}></Route>
            <Route path='/terminos' element={<Terminos></Terminos>}></Route>

            {/*Rutas Protegida*/}
            <Route path='/admin/dashboard' element={<ProtectedRoutes isAdmin={true}><Dashboard></Dashboard></ProtectedRoutes>}></Route>
            <Route path='/admin/listServices' element={<ProtectedRoutes isAdmin={true}><ServiceList></ServiceList></ProtectedRoutes>}></Route>
            <Route path='/admin/newService' element={<ProtectedRoutes isAdmin={true}><NewService></NewService></ProtectedRoutes>}></Route>
            <Route path='/admin/updateService/:id' element={<ProtectedRoutes isAdmin={true}><UpdateService></UpdateService></ProtectedRoutes>}></Route>
            <Route path='/admin/createSubcategorie/:id' element={<ProtectedRoutes isAdmin={true}><SubcategorieNew></SubcategorieNew></ProtectedRoutes>}></Route>
            <Route path='/admin/updateProduct' element={<ProtectedRoutes isAdmin={true}><UpdateProduct></UpdateProduct></ProtectedRoutes>}></Route>
            <Route path='/admin/listUsers' element={<ProtectedRoutes isAdmin={true}><UserList></UserList></ProtectedRoutes>}></Route>
            <Route path='/admin/updateUser/:id' element={<ProtectedRoutes isAdmin={true}><UpdateUser></UpdateUser></ProtectedRoutes>}></Route>
            <Route path='/admin/employees' element={<ProtectedRoutes isAdmin={true}><Employee></Employee></ProtectedRoutes>}></Route>
            <Route path='/admin/listReviews' element={<ProtectedRoutes isAdmin={true}><ListReviews></ListReviews></ProtectedRoutes>}></Route>
          </Routes>
        </Suspense>
        <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;
