import React from 'react';

const LoadingProgress = () => {
    return (
        <div className='containerLoading'>
            <div className='ring'>
                Loading
                <span></span>
            </div>
        </div>
    );
};

export default LoadingProgress;