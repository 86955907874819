import React, { Fragment, Suspense } from 'react'
import MetaData from './layout/metadata';
import LoadingProgress from './layout/LoadingProgress';
const Banner = React.lazy(() => import('./banner'));
const Nosotros = React.lazy(() => import('./nosotros'));
const Services = React.lazy(() => import('./services/services'));
const Reviews = React.lazy(() => import('./reviews/reviews'));
export const Inicio = () => {
    return (
        <Fragment>
            <MetaData title={"Inicio"}></MetaData>
            <Suspense fallback={<LoadingProgress></LoadingProgress>}>
                <section id='banner' className='containerInicio'>
                    <Banner></Banner>
                </section>
                <section id='services'>
                    <Services></Services>
                </section>
                <section id='about' >
                    <Nosotros></Nosotros>
                </section>
                <section id='reviews'>
                    <Reviews></Reviews>
                </section>
            </Suspense>
        </Fragment>
    )
}
